<template>
    <div>
		<navbar/>
		<side-bar-admin/>
        <div class="padding100">
			<div class="creation-container" >
				<div class="div-creation">
					<h4 class="title-card">Création d'une table</h4>
					<v-col >
						<v-select v-model="type_tag_selected" :items="typeTag" label="Choix du type de matériel" chips persistent-hint></v-select>
					</v-col>
					<v-col >
						<v-text-field
							v-model="idTable"
							:label="`id`"
							:prefix="getPrefix(type_tag_selected)"
							@input="handleInput"
							@paste="handlePaste"
						></v-text-field>
					</v-col>
					<v-col style="text-align: center; ">
						<v-btn text v-if="showCreation1 " @click.prevent="validationTable()" style="background-color: var(--vertfonce);">Valider la création</v-btn>
					</v-col>
				</div>
				<div class="div-creation">
						<h4 class="title-card">Attribution d'un matériel</h4>
						<v-col >
							<v-select v-model="tag_list_selected" :items="typeTag" label="Choix du type de matériel" chips persistent-hint @change="selectList"></v-select>
						</v-col>
						<v-col >
							<v-select v-model="tag_selected" :items="listGenerated" label="Choix du tag" chips persistent-hint @change="GetUsers" :filter="customFilter" :search-input.sync="searchQuery"></v-select>
						</v-col>
						<v-col >
							<v-select v-model="user_selected" :items="listUsers" label="Choix de l'utilisateur" chips persistent-hint @change="showValidation2 = true"></v-select>
						</v-col>
						<v-col>
							<v-btn text v-if="showValidation2 == true" @click.prevent="validationAttribution()" style="background-color: var(--vertfonce);">Valider l'attribution</v-btn>
						</v-col>
					</div>
			</div>
				<v-dialog v-model="dialog" max-width="290">
					<v-card>
						<v-card-title class="headline">Confirmation</v-card-title>
						<v-card-text>
							Êtes-vous sûr de vouloir créer une nouvelle table {{ tag_id }} ?
						</v-card-text>
						<v-card-actions>
							<v-btn color="blue darken-1" text @click="dialog = false">Non</v-btn>
							<v-btn color="blue darken-1" text @click="confirmValidation">Oui</v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>
        </div>
    </div>
</template>

<script>
import { switchToken } from '../../functions/test-token';
import SideBarAdmin from '../../components/SideBarAdmin.vue';
import navbar from '@/components/NavBarCloud.vue'
    export default {
	components: { 
		SideBarAdmin,
		navbar 
	},
        data () {
            return {
                typeTag: ['matrice', 'lecteur', 'implant', 'implant ppg', 'capteur ammoniaque', 'capteur environnement', 'sphère de jeu', 'sphère accéléromètre', 'vibration 400Hz' , 'vibration continue'],
                prefix: ['mat_', 'lct_', 'imp_', 'ppg1_', 'amq_', 'env_', 'sph_', 'spha_', 'vib_', 'vib2_'],
                type_tag_selected: '',
				tag_list_selected: '',
				tag_id: '',
				tagIdLength: null,
				listGenerated : [],
				listUsers : [],
				listEmail: [],
				listTag : [],
				user_selected : '',
				tag_selected: '',
                idTable: '',
				showCreation1 : false,
				showValidation1 : false,
				showValidation2 : false,
				dialog: false,
				errors: [],
				searchQuery: '',
            }
        },

        methods:{
			//Creation
            getPrefix(tag) {
                const index = this.typeTag.indexOf(tag);
                return this.prefix[index] || '';
            },
			validationTable() {
				this.tag_id = this.getPrefix(this.type_tag_selected) + this.idTable;
				this.dialog = true; // Affiche la fenêtre modale
			},
            confirmValidation(){
                this.dialog = false;
				if(!this.idTable ){
					this.errors.push("please enter all fields")
				}
				if(this.errors.length > 0){
					window.alert(this.errors)	
				}else{
					this.checkTagExistence();
				}
				this.errors = [];
				
            },
			handleInput(value) {
				if (value.length === 5) {
					this.showCreation1 = true;
				}
			},
			handlePaste(event) {
				setTimeout(() => {
					if (this.idTable && this.idTable.length >= 5) {
						this.showCreation1 = true;
					}
				}, 50); // Ajoutez un délai de 50ms
			},
			customFilter(item, queryText, itemText) {
				const hasValue = val => (val != null ? val : '');
				const text = hasValue(itemText);
				const query = hasValue(queryText);

				// Si le texte de l'élément commence par la requête, retournez true
				if (text.toString().toLowerCase().startsWith(query.toString().toLowerCase())) {
					return true;
				}

				// Sinon, vérifiez si le texte de l'élément contient la requête
				return text.toString().toLowerCase().indexOf(query.toString().toLowerCase()) > -1;
			},
			async checkTagExistence(){
				const checked = await Promise.all([this.selectTagExistence()])
				if(checked){
					if(this.tagIdLength > 0){
						window.alert('le tag id existe déjà dans la base de donnée')
						return false;
					}else{ 
						await this.CreateTagTables(this.type_tag_selected);
						await this.postTag(this.type_tag_selected);
						this.idTable = '';
						this.type_tag_selected = '';
						this.tag_list_selected = '';
						this.tag_selected = ''
						this.user_selected = '';
						window.alert('c\'est bon')
					}
				}else{
					return
				}
			},
			//2.1- Appelle de la requête qui récupère le tag_id
			async selectTagExistence(){
				const tag_id = encodeURIComponent(this.tag_id)
				const url = this.$api.getRESTApiUri() + `/admin/get-tag/${tag_id}`
				return fetch(url)
                .then(res => res.text())
                .then((result) => {
                    const data = JSON.parse(result);
					
					this.tagIdLength = data.length
				})
				.catch((error) => {
					console.log(error)
				});	
			},
			//3- On crée le nouveau tag dans la table de donnée tag_manager
			async postTag(type_tag){
				const accessToken = localStorage.getItem('accessToken');
				const url = this.$api.getRESTApiUri() + `/admin/liste/tag_creation`
				fetch(url, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						'Authorization': `Bearer ${accessToken}`
					},
					body: JSON.stringify({
						tag_id: this.tag_id,
						type_tag: type_tag,
					}),
				})
			.then(res => {
				if (res.status === 401) {
					switchToken().then(() => {
					// Refaites la requête avec le nouveau token
						this.postTag(type_tag);
					});
				}
				return res.text();
			})
			.then((result) => {
				const data = JSON.parse(result)
				console.log(data)
			})
			.catch((error) => {
				console.log(error)
			});		
			
			},
			//4- On crée les tables hub et max30101
			async CreateTagTables(type_tag){
				let url;
				const accessToken = localStorage.getItem('accessToken');
				console.log(type_tag)
				switch (type_tag) {
					case 'implant':
						url = this.$api.getRESTApiUri() + `/admin/table_creation/implant`;
						break;
					case 'implant ppg':
						url = this.$api.getRESTApiUri() + `/admin/table_creation/implant-ppg1`;
						break;
					case 'matrice':
						url = this.$api.getRESTApiUri() + `/admin/table_creation/matrice`;
						break;
					case 'capteur ammoniaque':
						url = this.$api.getRESTApiUri() + `/admin/table_creation/ammoniaque`;
						break;
					case 'capteur environnement':
						url = this.$api.getRESTApiUri() + `/admin/table_creation/environnement`;
						break;
					case 'sphère de jeu':
						url = this.$api.getRESTApiUri() + `/admin/table_creation/sphere`;
						break;
					case 'sphère accéléromètre':
						url = this.$api.getRESTApiUri() + `/admin/table_creation/vibration-sphere`;
						break;
					case 'vibration 400Hz':
						url = this.$api.getRESTApiUri() + `/admin/table_creation/vibration-sphere`;
						break;
					case 'vibration continue':
						url = this.$api.getRESTApiUri() + `/admin/table_creation/vibration-sphere`;
						break;
					case 'lecteur':
						url = this.$api.getRESTApiUri() + `/admin/table_creation/lecteur`;
						break;
				}
				return fetch(url, {
					method: 'POST',
						headers: {
							'Content-Type': 'application/json',
							'Authorization': `Bearer ${accessToken}`
						},
						body: JSON.stringify({
							tag_id : this.tag_id,
						}),
					})
				.then(res => {
					if (res.status === 401) {
						switchToken().then(() => {
							// Refaites la requête avec le nouveau token
							this.CreateTagTables(type_tag);
						});
					}
					return res.text();
				})
				.then((result) => {
					const data = JSON.parse(result)
					console.log(data)
				})
			.catch((error) => {
				console.log(error)
			});	
			},
			async selectList(){
				this.listGenerated = []
				if(this.tag_list_selected == 'implant'){
					const data = await this.GetTags();
					this.sortTagsName(data, 'imp')
				}else if(this.tag_list_selected == 'implant ppg'){
					const data = await this.GetTags();
					this.sortTagsName(data, 'ppg')
				}else if(this.tag_list_selected == 'matrice'){
					this.GetMatrice();
				}else if(this.tag_list_selected == 'capteur ammoniaque'){
					this.GetCapteurAmmo();
				}else if(this.tag_list_selected == 'capteur environnement'){
					this.GetEnv();
				}else if(this.tag_list_selected == 'sphère de jeu'){
					this.GetSphere();
				}else if(this.tag_list_selected == 'vibration 400Hz'){
					this.GetVibration();
				}else if(this.tag_list_selected == 'sphère accéléromètre'){
					this.GetSphere();
				}else if(this.tag_list_selected == 'vibration continue'){
					this.GetVibrationContinu();
				}else if(this.tag_list_selected == 'lecteur'){
					this.GetLecteur();
				}
			},

			async GetTags(){
				const url = this.$api.getRESTApiUri() + `/get-tag-id`;
				const accessToken = localStorage.getItem('accessToken');
				const headers = new Headers({
					'Content-Type': 'application/json',
					'Authorization': `Bearer ${accessToken}`
				});
				return fetch(url, { headers: headers })
					.then(res => {
					if (res.status === 401) {
						switchToken().then(() => {
						// Refaites la requête avec le nouveau token
							this.GetTags();
						});
					}
					return res.text();
				})
				.then((result) => {
					const data = JSON.parse(result)
					return data 
				})
				.catch((error) => {
					console.log(error)
				});
			},

			async GetMatrice(){
				const url = this.$api.getRESTApiUri() + `/get-matrice-id`;
				const accessToken = localStorage.getItem('accessToken');
				const headers = new Headers({
					'Content-Type': 'application/json',
					'Authorization': `Bearer ${accessToken}`
				});
				return fetch(url, { headers: headers })
					.then(res => {
					if (res.status === 401) {
						switchToken().then(() => {
						// Refaites la requête avec le nouveau token
							this.GetMatrice();
						});
					}
					return res.text();
				})
				.then((result) => {
					const data = JSON.parse(result)
					this.sortMatricesName(data);
				})
				.catch((error) => {
					console.log(error)
				});
			},

			async GetCapteurAmmo(){
				const url = this.$api.getRESTApiUri() + `/get-liste-ammoniaque-id`;
				const accessToken = localStorage.getItem('accessToken');
				const headers = new Headers({
					'Content-Type': 'application/json',
					'Authorization': `Bearer ${accessToken}`
				});
				return fetch(url, { headers: headers })
					.then(res => {
					if (res.status === 401) {
						switchToken().then(() => {
						// Refaites la requête avec le nouveau token
							this.GetCapteurAmmo();
						});
					}
					return res.text();
				})
				.then((result) => {
					const data = JSON.parse(result)
					this.sortCapteursName(data);
				})
				.catch((error) => {
					console.log(error)
				});
			},
			async GetEnv(){
				const url = this.$api.getRESTApiUri() + `/get-liste-environnement-id`;
				const accessToken = localStorage.getItem('accessToken');
				const headers = new Headers({
					'Content-Type': 'application/json',
					'Authorization': `Bearer ${accessToken}`
				});
				return fetch(url, { headers: headers })
					.then(res => {
					if (res.status === 401) {
						switchToken().then(() => {
						// Refaites la requête avec le nouveau token
							this.GetEnv();
						});
					}
					return res.text();
				})
				.then((result) => {
					const data = JSON.parse(result)
					this.sortCapteursName(data);
				})
				.catch((error) => {
					console.log(error)
				});
			},
			async GetLecteur(){
				const url = this.$api.getRESTApiUri() + `/get-matrice-id`;
				const accessToken = localStorage.getItem('accessToken');
				const headers = new Headers({
					'Content-Type': 'application/json',
					'Authorization': `Bearer ${accessToken}`
				});
				return fetch(url, { headers: headers })
				.then(res => {
					if (res.status === 401) {
						switchToken().then(() => {
						// Refaites la requête avec le nouveau token
							this.GetLecteur();
						});
					}
					return res.text();
				})
				.then((result) => {
					const data = JSON.parse(result)
					console.log(data)
					this.sortLecteursName(data);
				})
				.catch((error) => {
					console.log(error)
				});
			},
			async GetSphere(){
				const url = this.$api.getRESTApiUri() + `/get-liste-sphere-id`;
				const accessToken = localStorage.getItem('accessToken');
				const headers = new Headers({
					'Content-Type': 'application/json',
					'Authorization': `Bearer ${accessToken}`
				});
				return fetch(url, { headers: headers })
					.then(res => {
					if (res.status === 401) {
						switchToken().then(() => {
						// Refaites la requête avec le nouveau token
							this.GetSphere();
						});
					}
					return res.text();
				})
				.then((result) => {
					const data = JSON.parse(result)
					this.sortCapteursName(data);
				})
				.catch((error) => {
					console.log(error)
				});
			},
			async GetVibration(){
				const url = this.$api.getRESTApiUri() + `/get-liste-vibration-id`;
				const accessToken = localStorage.getItem('accessToken');
				const headers = new Headers({
					'Content-Type': 'application/json',
					'Authorization': `Bearer ${accessToken}`
				});
				return fetch(url, { headers: headers })
					.then(res => {
					if (res.status === 401) {
						switchToken().then(() => {
						// Refaites la requête avec le nouveau token
							this.GetVibration();
						});
					}
					return res.text();
				})
				.then((result) => {
					const data = JSON.parse(result)
					this.sortVibration(data);
				})
				.catch((error) => {
					console.log(error)
				});
				
			},
			async GetVibrationContinu(){
				const url = this.$api.getRESTApiUri() + `/get-liste-vibration-id`;
				const accessToken = localStorage.getItem('accessToken');
				const headers = new Headers({
					'Content-Type': 'application/json',
					'Authorization': `Bearer ${accessToken}`
				});
				return fetch(url, { headers: headers })
					.then(res => {
					if (res.status === 401) {
						switchToken().then(() => {
						// Refaites la requête avec le nouveau token
							this.GetVibrationContinu();
						});
					}
					return res.text();
				})
				.then((result) => {
					const data = JSON.parse(result)
					console.log(data)
					this.sortVibrationContinu(data);
				})
				.catch((error) => {
					console.log(error)
				});
				
			},
			async GetVibration(){
				const url = this.$api.getRESTApiUri() + `/get-liste-vibration-id`;
				const accessToken = localStorage.getItem('accessToken');
				const headers = new Headers({
					'Content-Type': 'application/json',
					'Authorization': `Bearer ${accessToken}`
				});
				return fetch(url, { headers: headers })
					.then(res => {
					if (res.status === 401) {
						switchToken().then(() => {
						// Refaites la requête avec le nouveau token
							this.GetVibration();
						});
					}
					return res.text();
				})
				.then((result) => {
					const data = JSON.parse(result)
					this.sortVibration(data);
				})
				.catch((error) => {
					console.log(error)
				});
				
			},
			async GetUsers() {
				const url = this.$api.getRESTApiUri() + `/get-users`;
				const accessToken = localStorage.getItem('accessToken');
				const headers = new Headers({
					'Content-Type': 'application/json',
					'Authorization': `Bearer ${accessToken}`
				});
				return fetch(url, { headers: headers })
					.then(res => {
					if (res.status === 401) {
						switchToken().then(() => {
						// Refaites la requête avec le nouveau token
						this.GetUsers();
						});
					}
					return res.text();
				})
				.then((result) => {
					const data = JSON.parse(result);
					for(let i = 0; i < data.length; i++){
						this.listUsers.push(data[i].email)
					}
				})
				.catch((error) => {
					console.log(error);
				});
			},
			async validationAttribution(){
				const checkedEmail = await Promise.all([this.selectEmailAttribution()]);
				const checkedTag = await Promise.all([this.selectTagAttribution()]);
				if(checkedEmail && checkedTag){
					if(this.listEmail == null){
						window.alert('mail attribué')
						 this.InsertMailTagManager();
					}else{
						if(this.listEmail.includes(this.user_selected)){
							window.alert('l\'email: '+ this.user_selected + ' est déjà attribué')
						}else{
							window.alert('mail attribué')
							this.InsertMailTagManager();
						}
					}
					if(this.listTag == null){
						window.alert('tag attribué')
						this.InsertTagUserAccount();
					}else{
						if(this.listTag.includes(this.tag_selected)){
							window.alert('le tag est déjà attribué')
						}else{
							this.InsertTagUserAccount();
							window.alert('tag attribué')
						}
					}
				}else{
					return
				}
            },
			//3.1- Check de l'attribution d'un utilisateur à son tag 
			async selectEmailAttribution(){
				const tag_id = encodeURIComponent(this.tag_selected)
				const type_tag = encodeURIComponent(this.tag_list_selected)
				const url = this.$api.getRESTApiUri() + `/admin/get-email-attribution/${tag_id}/${type_tag}`
				return fetch(url)
                .then(res => res.text())
                .then((result) => {
                    const data = JSON.parse(result);
					this.listEmail = data[0].email;
				})
				.catch((error) => {
					console.log(error)
				});
			},

			//3.1- Check de l'attribution d'un tag à son utilisateur
			async selectTagAttribution(){
				const email = encodeURIComponent(this.user_selected)
				const type_tag = encodeURIComponent(this.tag_list_selected)
				const url = this.$api.getRESTApiUri() + `/admin/get-tag-attribution/${email}/${type_tag}`
				return fetch(url)
                .then(res => res.text())
                .then((result) => {
                    const data = JSON.parse(result);
					this.listTag = data[0].tag_attributed;
				})
				.catch((error) => {
					console.log(error)
				});
			},

			//4.1- Attribution du tag au user (tag -> user_account)
			async InsertTagUserAccount(){
				const url = this.$api.getRESTApiUri() + `/admin/tag-attribution-to-user`;
				fetch(url, {
					method: 'PUT',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify({
							email: this.user_selected,
							tag_id: this.tag_selected,
							type_tag: this.tag_list_selected
						}),
				})
				// Converting to JSON
				.then(response => response.json())
				// Displaying results to console
				.then(json => console.log(json));		
			},

			//4.2- Attribution du user au tag (user -> tag_manager)
			async InsertMailTagManager(){
				const url = this.$api.getRESTApiUri() + `/admin/mail-attribution-to-tag`;
				fetch(url, {
					method: 'PUT',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify({
							email: this.user_selected,
							tag_id: this.tag_selected,
							type_tag : this.tag_list_selected
						}),
				})
				// Converting to JSON
				.then(response => response.json())
				// Displaying results to console
				.then(json => console.log(json));		
			},
			sortTagsName(liste_tag_name, prefix){
				const sortedTags = liste_tag_name.map(item => item.tag_id).sort((a, b) => {
            // Si a est un nombre et b une lettre, a vient en premier
					if (!isNaN(a[0]) && isNaN(b[0])) return -1;
					// Si a est une lettre et b un nombre, b vient en premier
					if (isNaN(a[0]) && !isNaN(b[0])) return 1;
					// Sinon, comparez normalement
					return a.localeCompare(b);
				});
				this.listGenerated = sortedTags.filter(tag => tag.startsWith(prefix));
			},
			sortCapteursName(liste_tag_name){
				const sortedTags = liste_tag_name.map(item => item.capteur_id).sort((a, b) => {
            // Si a est un nombre et b une lettre, a vient en premier
					if (!isNaN(a[0]) && isNaN(b[0])) return -1;
					// Si a est une lettre et b un nombre, b vient en premier
					if (isNaN(a[0]) && !isNaN(b[0])) return 1;
					// Sinon, comparez normalement
					return a.localeCompare(b);
				});
				this.listGenerated = sortedTags
			},
			sortMatricesName(liste_tag_name){
				const sortedTags = liste_tag_name.map(item => item.matrice_id).sort((a, b) => {
            // Si a est un nombre et b une lettre, a vient en premier
					if (!isNaN(a[0]) && isNaN(b[0])) return -1;
					// Si a est une lettre et b un nombre, b vient en premier
					if (isNaN(a[0]) && !isNaN(b[0])) return 1;
					// Sinon, comparez normalement
					return a.localeCompare(b);
				});
				this.listGenerated = sortedTags.filter(tag => tag.startsWith('mat_'));
			},
			sortLecteursName(liste_tag_name){
				const sortedTags = liste_tag_name.map(item => item.matrice_id).sort((a, b) => {
            // Si a est un nombre et b une lettre, a vient en premier
					if (!isNaN(a[0]) && isNaN(b[0])) return -1;
					// Si a est une lettre et b un nombre, b vient en premier
					if (isNaN(a[0]) && !isNaN(b[0])) return 1;
					// Sinon, comparez normalement
					return a.localeCompare(b);
				});
				this.listGenerated = sortedTags.filter(tag => tag.startsWith('lct_'));
			},
			sortVibrationContinu(liste_tag_name){
				const sortedTags = liste_tag_name.map(item => item.capteur_id).sort((a, b) => {
            // Si a est un nombre et b une lettre, a vient en premier
					if (!isNaN(a[0]) && isNaN(b[0])) return -1;
					// Si a est une lettre et b un nombre, b vient en premier
					if (isNaN(a[0]) && !isNaN(b[0])) return 1;
					// Sinon, comparez normalement
					return a.localeCompare(b);
				});
				this.listGenerated = sortedTags.filter(tag => tag.startsWith('vib2_'));
			},
			sortVibration(liste_tag_name){
				const sortedTags = liste_tag_name.map(item => item.capteur_id).sort((a, b) => {
            // Si a est un nombre et b une lettre, a vient en premier
					if (!isNaN(a[0]) && isNaN(b[0])) return -1;
					// Si a est une lettre et b un nombre, b vient en premier
					if (isNaN(a[0]) && !isNaN(b[0])) return 1;
					// Sinon, comparez normalement
					return a.localeCompare(b);
				});
				this.listGenerated = sortedTags.filter(tag => tag.startsWith('vib_'));
			},
        }
    }
</script>

<style>
.creation-container{
	display: flex;
	justify-content: space-around;
	margin: 150px 0px;
}
.div-creation{
	background-color: white;
	border-radius: 20px;
	padding: 20px;
	width: 20%;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.title-card{
	font-family: Roboto;
    font-size: 1.5rem;
    color: var(--bleu);
	margin-bottom: 20px;
	text-align: center;
}
</style>